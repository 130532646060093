import PopcornBites from "../../img/products/snackseries/2nsspb1.webp";
import LarbBites from "../../img/products/snackseries/2nsslb1.webp";
import CrispyNuggets from "../../img/products/snackseries/2nsscn1.webp";
import OriginalFriedChicken from "../../img/products/thaiseries/tsosfc1.png";
import SpicyFriedChicken from "../../img/products/thaiseries/tssfc1.png";
import SpicyPatties from "../../img/products/thaiseries/tssp1.png";
import LarbBalls from "../../img/products/thaiseries/tsbl1.png";
import CookedMince from "../../img/products/jseries/nacm.webp";
import CrispyKatsu from "../../img/products/jseries/nack.webp";
export const snackSeriesData = [
    {
        id: 0,
        path: "popcorn_bites/",
        title: "productsHome.snackSeries.productTitle1",
        weight: "(170 g.)",
        price: "99 THB",
        image: PopcornBites,
        alt: "productDetail.productImageAlt1",
    },
    {
        id: 1,
        path: "thai_spicy_bites/",
        title: "productsHome.snackSeries.productTitle2",
        weight: "(170 g.)",
        price: "99 THB",
        image: LarbBites,
        alt: "productDetail.productImageAlt2",
    },
    {
        id: 2,
        path: "crispy_nuggets/",
        title: "productsHome.snackSeries.productTitle3",
        weight: "(170 g.)",
        price: "99 THB",
        image: CrispyNuggets,
        alt: "productDetail.productImageAlt3",
    },
];

export const thaiSeriesData = [
    {
        id: 3,
        path: "original_style_fried_chicken/",
        title: "productsHome.thaiSeries.productTitle1",
        weight: "(180 g.)",
        price: "99 THB",
        image: OriginalFriedChicken,
        alt: "productDetail.productImageAlt4",
    },
    {
        id: 4,
        path: "spicy_fried_chicken/",
        title: "productsHome.thaiSeries.productTitle2",
        weight: "(180 g.)",
        price: "99 THB",
        image: SpicyFriedChicken,
        alt: "productDetail.productImageAlt5",
    },
    {
        id: 5,
        path: "mini_thai_spicy_patties_with_lava_sauce/",
        title: "productsHome.thaiSeries.productTitle3",
        weight: "(150 g.)",
        price: "99 THB",
        image: SpicyPatties,
        alt: "productDetail.productImageAlt6",
    },
    {
        id: 6,
        path: "larb_balls/",
        title: "productsHome.thaiSeries.productTitle4",
        weight: "(160 g.)",
        price: "99 THB",
        image: LarbBalls,
        alt: "productDetail.productImageAlt7",
    },
];

export const jSeriesData = [
    {
        
        id: 7,
        path: "cooked_mince/",
        title: "productsHome.jSeries.productTitle1",
        weight: "(180 g.)",
        price: "99 THB",
        image: CookedMince,
        alt: "productDetail.productImageAlt8",
    },
    {
        id: 8,
        path: "crispy_katsu/",
        title: "productsHome.jSeries.productTitle2",
        weight: "(160 g.)",
        price: "99 THB",
        image: CrispyKatsu,
        alt: "productDetail.productImageAlt9",
    },
]
