import React from "react";
import Layout from "../components/Layout";
import "../scss/products.scss";
import { StaticImage } from "gatsby-plugin-image";
import SnackSeries from "../components/ProductsHome/SnackSeries";
import ThaiSeries from "../components/ProductsHome/ThaiSeries";
import JSeries from "../components/ProductsHome/JSeries";
import Helmet from "react-helmet";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";

const Products = (props) => {
    const { t } = useTranslation();
    return (
        <Layout title={"Plant-Based Products"} description={"Think plant-based is boring? Think again. FIRST PRIDE offers a delicious range of plant-based protein that is easy to enjoy."}>
            <Helmet> 
                <link rel="alternate" href="https://www.firstpridethailand.com/products/" hreflang="th-th" />
                <link rel="alternate" href="https://www.firstpride.sg/products" hreflang="en-sg" />
            </Helmet>
                <div className="products">
                    <div className="productsBanner">
                        <div className="productsBannerContent" style={{ whiteSpace: "pre-wrap" }}>
                            <h1>{t("productsPage.title1")}</h1>
                            <h5 className="p-regular">
                                {t("productsPage.subtitle1")}
                                <b>{t("productsPage.boldcontent1")}</b>
                                {t("productsPage.subtitle2")}
                                <span className="together">{t("productsPage.subtitle3")}</span>
                                <br />
                                {t("productsPage.subtitle4")}
                                <b>{t("productsPage.boldcontent2")}</b>
                                {t("productsPage.subtitle5")}
                            </h5>
                            {/* <h5>FIRST PRIDE offers a delicious range of plant-based protein</h5>
                        <h5>that’s easy to enjoy.</h5> */}
                        </div>
                    </div>
                    <div className="productsContentWrapper" style={{ whiteSpace: "pre-wrap" }}>
                        <div className="productsHeader anchor-prod" id="snacks">
                            <StaticImage src="../img/snackseries.webp" alt="SnackSeries" className="productsImage" />
                            <div className="productsContentTitle">
                                <h2>Snack Series</h2>
                                <p className="p-regular">
                                    {t("productsPage.snacksubtitle1")}
                                    <br />
                                    {t("productsPage.snacksubtitle2")}
                                </p>
                            </div>
                        </div>
                        <div className="productsSnackSeries">
                            <SnackSeries />
                        </div>
                        <div className="productsHeader thaiSeries anchor-prod" id="thai">
                            <StaticImage src="../img/thaiseries.webp" alt="ThaiSeries" className="productsImage" />
                            <div className="productsContentTitle">
                                <h2>Thai Taste Series</h2>
                                <p style={{ color: "var(--darkorange)" }} className="p-regular">
                                    {t("productsPage.thaisubtitle1")}
                                </p>
                            </div>
                        </div>
                        <div className="productsSnackSeries">
                            <ThaiSeries />
                        </div>
                        <div className="productsHeader JSeries anchor-prod" id="J">
                            <StaticImage src="../img/Allium-free-series.png" alt="ThaiSeries" className="productsImage" />
                            <div className="productsContentTitle">
                                <h2>Allium-free Series</h2>
                                <p style={{ color: "var(--darkorange)" }} className="p-regular">
                                    {t("productsPage.jsubtitle1")}
                                </p>
                            </div>
                        </div>
                        <div className="productsSnackSeries">
                            <JSeries />
                        </div>
                    </div>
                </div>
        </Layout>
    );
};

export default (Products);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "navbar", "footer", "products"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;